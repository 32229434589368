.nav-bar {
    background: #F0FFFF;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 3;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
  
    .logo-container {
      display: flex;
      align-items: center;
      text-decoration: none;
  
      .logo {
        height: 40px;
        max-width: 100px;
        margin-right: 10px;
        border-radius: 25%;
      }
  
      .company-name {
        font-size: 18px;
        color: black;
        text-decoration: none;
      }
    }
  
    nav {
      display: flex;
      align-items: center;
  
      .nav-link {
        font-size: 18px;
        margin: 0 15px;
        text-decoration: none;
        color: black; /* Initial color is black */
        transition: color 0.3s ease-in-out;
        font-weight: normal; /* Ensure default font weight is normal */
  
        &:hover {
          color: #007BFF; /* Blue color on hover */
        }
  
        &.active {
          font-weight: bold; /* Bold only the active link */
        }
      }
    }
  
    .menu-icon {
      display: none; /* Hide by default */
  
      img {
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
    }
  
    .mobile-menu {
      display: none; /* Initially hidden */
    }
  }
  
  @media (max-width: 768px) {
    .nav-bar {
      .company-name {
        display: none; /* Hide company name on mobile */
      }
  
      nav {
        display: none; /* Hide nav links on mobile */
      }
  
      .menu-icon {
        display: flex; /* Show menu icon on mobile */
        align-items: center;
        justify-content: center;
      }
  
      .mobile-menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 2;
        transition: all 0.3s ease-in-out;
        
        .nav-link {
          margin: 15px 0;
          font-size: 24px;
          color: black;
          text-decoration: none;
  
          &:hover {
            color: #007BFF;
          }
  
          &.active {
            font-weight: bold;
          }
        }
      }
    }
  }
  