.contact-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  width: 100%;
}

.contact {
  // width: 66.67%; /* 2/3 of the page width */
  // background-color: #333; /* Dark gray background for the contact section */
  margin: 0 auto; /* Center within the flex container */
}

form {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.email-inputs {
  padding: 8px;
  border: none;
  border-bottom: 1px solid #000000; /* White underline */
  // background: #333333;
  color: #000000;
  font-size: 16px;
  width: 100%;

  input {
    width: 100%;
    height: 40px;
  }

  textarea {
    height: 100px;
    width: 100%;
    box-sizing: border-box;
  }
}

.form-row.flex {
  flex-direction: row;
  gap: 10px; /* Space between first name and last name fields */
}

.icon {
  font-size: 40px;
  padding: 15px;
}
