@tailwind base;
@tailwind components;
@tailwind utilities;

.content-section {
    width: 66.67%;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
}

.home {
    width: 100%; /* Full width */
    background-image: url('../../assets/images/banner.png');
    background-size: 100% auto; /* Stretch across the full width, natural height */
    background-position: top center; /* Start at the top, center horizontally */
    background-repeat: no-repeat; /* Prevents image from repeating */
    padding: 50px 0; /* Adjust padding to ensure text is not cut off */
}

/* Mobile Styles */
@media (max-width: 768px) {
    .home {
        background-size: cover; /* Stretch to cover entire area */
        background-position: center; /* Center the image */
    }
}

.main-title {
    font-size: 3rem; /* Huge title size */
    font-weight: bold;
    margin-bottom: 20px;
    color: black; /* Set text to black for contrast */
}

.subheader {
    margin: 0 auto 30px auto;
    color: black;
    width: 66.6%;
    text-align: center;
}

.subheader-mobile {
    margin: 0 auto 30px auto;
    color: black;
    width: 90%;
    text-align: center;
}

.get-started-button {
    background-color: #007BFF; /* Blue color */
    color: white;
    border: none;
    padding: 10px 30px;
    font-size: 1.2rem;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 20px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0056b3; /* Darker shade on hover */
    }
}

.response-time {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;

    .profile-pic {
        width: 50px; /* Size for circular photo */
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;
        object-fit: cover;
    }
}

.golden-ratio-0 {
    font-size: 10px;
}

.golden-ratio-1 {
    font-size: 15px;
}

.golden-ratio-125 {
    font-size: 17.5px;
}

.golden-ratio-15 {
    font-size: 20px;
}

.golden-ratio-2 {
    font-size: 25px;
}

.golden-ratio-3 {
    font-size: 40px;
    line-height: 1.1;
}

.golden-ratio-4 {
    font-size: 64.72px;
}